import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import jquery from "jquery";
export default class extends Controller {
  static targets = ['display', 'selectable']
  static values = {
    documentIds: Array
  }
  selectableTargetConnected(element) {
    let documentId = element.dataset.documentId.toString()
    element.checked = (this.documentIdsValue.indexOf(documentId) != -1)
    this.updateDisplay()
  }
  updateDisplay() {
    let ids = []
    this.selectableTargets.forEach((element) => {
      if (element.checked && !element.closest('turbo-frame').hidden) {
        ids.push(element.dataset.documentId.toString())
      }
    })
    if (this.hasDisplayTarget) {
      if (ids.length > 0) {
        jquery(this.displayTarget).fadeIn()
      } else {
        jquery(this.displayTarget).fadeOut()
      }
    }
    this.documentIdsValue = ids;
  }
  displayTargetConnected() {
    this.updateDisplay()
  }
  displayTargetDisconnected() {
    this.updateDisplay()
  }
  change() {
    this.updateDisplay()
  }
  changeAll(event) {
    let checked = event.target.checked
    this.selectableTargets.forEach((element) => {
      element.dataset.fileSelectState = checked
      element.checked = checked
    })
    this.updateDisplay()
  }
  appendDocumentIds(event) {
    event.preventDefault()
    let href = event.currentTarget.href
    href = href + '?document_ids=' + this.documentIdsValue
    let frame = document.getElementById(event.currentTarget.dataset.turboFrame)
    if (frame == undefined) {
      frame = event.currentTarget.closest('turbo-frame')
    }
    if (frame != undefined) {
      frame.src = href
      frame.reload()
    } else {
      Turbo.visit(href)
    }
  }
}