import { Controller } from "@hotwired/stimulus"
import dragula from "dragula"

export default class extends Controller {
  static values = { maximum: { type: Number, default: 1 } }
  static targets = ["item"]

  initialize() {
    this.dragula = dragula({
      copy: false,
      revertOnSpill: true,
      accepts: this.canAcceptElement.bind(this)
    })
    this.dragula.on("drop", this.dropElement.bind(this))
  }

  itemTargetConnected(element) {
    this.dragula.containers.push(element)
  }

  itemTargetDisconnected(element) {
    const index = this.dragula.containers.indexOf(element)
    if (index > -1) {
      this.dragula.containers.splice(index, 1)
    }
  }

  canAcceptElement(element, target, source, sibling) {
    return (source.dataset.link) && (target.dataset.position)
    return true
  }

  dropElement(droppedElement, target, source, sibling) {
    const link = document.getElementById(source.dataset.link)
    let newPosition = this.maximumValue - 1
    const currentPosition = parseInt(source.dataset.position)
    if (target) {
      const targetPosition = parseInt(target.dataset.position)
      if (targetPosition == 1) {
        newPosition = 1
      } else {
        if (targetPosition < currentPosition) {
          newPosition = targetPosition
        } else {
          newPosition = targetPosition + 1
        }
      }
    }
    link.dataset.dynamicComponentPositionParam = newPosition
    link.dispatchEvent(new MouseEvent("click"))
  }
}