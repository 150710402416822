import { Controller } from "@hotwired/stimulus"
import morphdom from "morphdom"

export default class extends Controller {
  async renderWithMorphdom(event) {
    const defaultRenderer = event.detail.render
    event.detail.render = async (streamElement) => {
      const existingElement = document.getElementById(streamElement.target)
      if (existingElement) {
        if (existingElement.dataset.morph == "true") {
          const existingContents = existingElement
          const newContents = streamElement.firstElementChild.innerHTML
          if (existingContents) {
            morphdom(existingContents, newContents, {
              childrenOnly: true
            })
          }
        } else {
          defaultRenderer(streamElement)
        }
      }
    }
  }
}