import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "label", "map"]

  mapTargetConnected() {
    this.setupMap()
  }

  getPosition(evt) {
    evt.preventDefault()
    if (!navigator.geolocation) {
      this.reportNoGeolocation()
    } else {
      navigator.geolocation.getCurrentPosition(this.gotCurrentPosition.bind(this), this.getPositionFailed.bind(this))
    }
  }

  reportNoGeolocation() {
    if (this.hasLabelTarget) {
      this.labelTarget.text = "Geolocation is not supported"
    }
  }

  gotCurrentPosition(position) {
    const inputField = this.inputField()
    if (inputField) {
      const data = { latitude: position.coords.latitude, longitude: position.coords.longitude }
      const json = JSON.stringify(data)
      inputField.value = json
      this.setupMap()
      this.dispatch("updated")
    }
  }

  getPositionFailed() {
    if (this.hasLabelTarget) {
      this.labelTarget.text = "Unable to retrieve your location"
    }
  }

  inputField() {
    return this.hasFieldTarget ? this.fieldTarget.querySelector("input") : null
  }

  mapFrame() {
    return this.hasMapTarget ? this.mapTarget.querySelector("iframe") : null
  }

  getCurrentData() {
    const inputField = this.inputField()
    if (inputField) {
      const json = inputField.value
      return (json == "") ? null : JSON.parse(json)
    } else {
      return null
    }
  }

  setupMap() {
    const data = this.getCurrentData()
    const frame = this.mapFrame()
    if (data) {
      if (frame) {
        const startLatitude = parseFloat(data.latitude) - 0.01
        const endLatitude = parseFloat(data.latitude) + 0.01
        const startLongitude = parseFloat(data.longitude) - 0.01
        const endLongitude = parseFloat(data.longitude) + 0.01
        const coords = encodeURI(`${startLongitude},${startLatitude},${endLongitude},${endLatitude}`)
        frame.src = `https://www.openstreetmap.org/export/embed.html?bbox=${coords}&amp;layer=mapnik`
        frame.classList.remove("d-none")
      }
    } else {
      if (frame) {
        frame.classList.add("d-none")
      }
    }
  }
}