import { Controller } from "@hotwired/stimulus"
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default class extends Controller {
  static values = { labels: String, data: String, colours: String, datasetTitle: String, chartTitle: String }

  connect() {
    const labelsArray = this.labelsValue.split(',')
    const dataArray = this.dataValue.split(',').map(v => parseInt(v))
    const colourArray = this.coloursValue.split(',')
    const data = {
      labels: labelsArray,
      datasets: [{
        data: dataArray,
        backgroundColor: colourArray,
      }],
    }
    const config = {
      type: 'doughnut',
      data: data,
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
          }
        },
      }
    }
    const ctx = this.element.getContext('2d');
    new Chart(ctx, config);
  }
}