import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  threshold = 840

  connect() {
    this.position = this.element.position
    this.resizeObserver = new ResizeObserver(this.onResize.bind(this))
    this.resizeObserver.observe(document.querySelector("body"))
    this.storedPosition = 25
    this.sidebarVisible = true
    this.showOrHideSidebar(this.element.clientWidth > this.threshold)
  }

  disconnect() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
      this.resizeObserver = null
    }
  }

  updatePosition(event) {
    if (event.target.position && (event.target.position != this.previousPosition)) {
      this.previousPosition = event.target.position
      this.dispatch("resized", { bubbles: true })
    }
  }

  showOrHideSidebar(hideSidebar) {
    if (hideSidebar) {
      if (this.sidebarVisible) {
        this.sidebarVisible = false
        this.storedPosition = this.element.position
        this.element.position = 0
        this.element.querySelector("aside.navigation").style.opacity = 0
        this.element.querySelector("sl-icon[slot=divider").style.display = "none"
        this.element.disabled = true
      }
    } else {
      if (!this.sidebarVisible) {
        this.element.position = this.storedPosition
        this.element.querySelector("aside.navigation").style.opacity = 1
        this.element.querySelector("sl-icon[slot=divider").style.display = "block"
        this.element.disabled = false
        this.sidebarVisible = true
      }
    }
  }

  onResize(entries) {
    const entry = entries[0]
    if (entry) {
      this.showOrHideSidebar(this.threshold > entry.contentRect.width)
    }
  }
}