import "@hotwired/turbo"
import "@rails/activestorage";
import "trix";
import "@rails/actiontext";
import "../channels/index.js";
import "../controllers/index.js";
import "../javascripts/hyper";
import BugsnagPerformance from "@bugsnag/browser-performance";

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

BugsnagPerformance.start("b443a3fd5605ed204644e097cc477d35")