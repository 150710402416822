import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String, signInUrl: String }

  urlValueChanged(newValue) {
    if (newValue) {
      this.startChecking()
    } else {
      this.stopChecking()
    }
  }

  disconnect() {
    this.stopChecking()
  }

  startChecking() {
    this.refreshTimer = setInterval(() => {
      this.checkLoginStatus()
    }, 60000)
  }

  async checkLoginStatus() {
    if (this.hasUrlValue) {
      const response = await fetch(this.urlValue, { cache: "no-cache" })
      if (response.status != 200) {
        window.location.href = this.signInUrlValue
      }
    }
  }

  stopChecking() {
    if (this.refreshTimer) {
      clearInterval(this.refreshTimer)
    }
  }
}